<template>
	<div class="page edit-page">
		<div class="top-wrapper">
			<div class="title"> {{ 85 > scrollTop ? title : ''}} </div>
			<div class="actions">
				<el-button type="primary" plain size="small" class="cancel" @click="goBack"> 取消 </el-button>
				<template v-if="id && (isSuperAdmin || isAdmin) && status !== 0">
					<el-button type="primary" size="small" class="submit" @click="submit"> 保存 </el-button>
				</template>
				<template v-else>
					<el-button type="primary" size="small" class="submit" @click="submit"> 存为草稿 </el-button>
					<el-button type="primary" size="small" class="submit" @click="release"> 提交 </el-button>
				</template>
			</div>
		</div>

		<div class="content-wrapper">
			<el-row :gutter="20" type="flex" class="content-row">
				<el-col :lg="15" :md="15" :sm="15" :xs="24">
					<div class="form-container pa20 pb0">
						<el-form ref="form" :model="form" :rules="rules">
							<div class="inline-form-item">
								<el-form-item label="资源" prop="source">
									<el-select v-model="form.source" filterable placeholder="请选择资源">
										<el-option v-for="option in sourceOptions" :key="option.id" :label="option.name"
											:value="option.id"></el-option>
									</el-select>
								</el-form-item>
								<el-form-item label="内容" prop="detail">
									<el-select v-model="form.detail" filterable placeholder="请选择内容">
										<el-option v-for="option in contentOptions" :key="option.id"
											:label="option.name" :value="option.id"></el-option>
									</el-select>
								</el-form-item>
							</div>

							<el-form-item label="主题" prop="title">
								<el-input v-model="form.title" placeholder="请输入主题" maxlength="50" show-word-limit
									class="show-word-pr-2" @blur="form.title=form.title.trim()"></el-input>
							</el-form-item>

							<el-form-item v-if="showFormSender" label="请选择接收对象">
								<el-checkbox v-model="form.sendType" :true-label="1" :false-label="1"
									class="send-type-checkbox"> 以通讯组为单位选择 </el-checkbox>
								<el-checkbox v-model="form.sendType" :true-label="2" :false-label="2"
									class="send-type-checkbox"> 以签约类型为单位选择 </el-checkbox>
								<el-checkbox v-model="form.sendType" :true-label="4" :false-label="4"
									class="send-type-checkbox"> 以经销商为单位选择 </el-checkbox>
							</el-form-item>

							<el-form-item v-if="showFormSender" label="">
								<el-cascader v-if="form.sendType === 1" :options="departmentOptions"
									:props="departmentProps" v-model="sendTargetDepartment" clearable
									placeholder="请选择通讯组" @visible-change="depCascaderVisableChange"
									:popper-class="depClass" class="full-width"></el-cascader>
								<el-select v-else-if="form.sendType === 2" v-model="sendTargetSign" multiple filterable
									clearable placeholder="请选择签约类型" class="full-width">
									<el-option v-for="option in contractOptions" :key="option.id" :label="option.name"
										:value="option.id"></el-option>
								</el-select>
								<el-select v-else-if="form.sendType === 4" v-model="sendTargetAgent" multiple filterable
									reserve-keyword remote clearable :remote-method="remoteDealerMethod" placeholder="请选择经销商" class="full-width">
									<el-option v-for="option in agentOptions" :key="option.id" :label="option.name"
										:value="option.id"></el-option>
								</el-select>
							</el-form-item>

							<el-form-item v-if="showFormSender" label="接收/抄送 用户">
								<el-select v-model="sendTargetUser" placeholder="请选择用户"
									multiple filterable reserve-keyword remote clearable :remote-method="remoteUserMethod"
									popper-class="user-select" class="full-width">
									<el-option v-for="(option,i) in userOptions" :key="i" :label="option.fullName"
										:value="option.id"></el-option>
								</el-select>
							</el-form-item>

							<el-form-item label="产品名称" prop="deviceIdList">
								<el-cascader :options="machineOptions" :props="machineProps" :show-all-levels="false"
									v-model="form.deviceIdList" filterable clearable placeholder="请选择产品"
									@visible-change="proCascaderVisableChange" :popper-class="proClass"
									class="full-width font14">
								</el-cascader>
							</el-form-item>

							<el-form-item label="主题内容" prop="content" class="editor-wrapper">
								<RichTextEditor :content.sync="form.content" placeholder="请输入主题内容"
									@blur="$refs['form'].validateField('content')"></RichTextEditor>
							</el-form-item>

							<el-form-item label="固件下载地址">
								<div class="inline-form-item full-width">
									<el-form ref="firmware-form" :model="downloadItem" :rules="firmwareRules"
										class="firmware-form">
										<el-form-item>
											<el-input v-model="downloadItem.firmwareName" placeholder="请输入文字描述"
												clearable class="mr10 show-word-clearable-pr-2" maxlength="50"
												show-word-limit
												@blur="downloadItem.firmwareName=downloadItem.firmwareName.trim()">
											</el-input>
										</el-form-item>
										<el-form-item prop="firmwareLink">
											<el-input v-model="downloadItem.firmwareLink"
												placeholder="请输入Link(http://... 或 https://...)" clearable
												maxlength="2000" class="mr10"
												@blur="downloadItem.firmwareLink=downloadItem.firmwareLink.trim()">
											</el-input>
										</el-form-item>
									</el-form>
									<el-button class="firmware-btn"
										:disabled="!downloadItem.firmwareName || !downloadItem.firmwareLink"
										@click="addDownloadItem"> 添加 </el-button>
								</div>
								<div v-for="(downloadItem, index) in form.firmwareList" :key="index"
									class="link-wrapper">
									<el-tag type="info" closable class="link-tag"
										@close="removeDownloadItem(downloadItem)">
										{{ downloadItem.firmwareName }} : {{ downloadItem.firmwareLink }}
									</el-tag>
								</div>
							</el-form-item>

							<el-form-item label="上传附件">
								<Upload ref="upload" :files.sync="uploadNormalFiles"
									:failedFiles.sync="uploadFailedFiles" class="full-width"></Upload>
							</el-form-item>
						</el-form>
					</div>
				</el-col>

				<el-col :lg="9" :md="9" :sm="9" :xs="24">
					<div class="upload-container pa20 pb0">
						<div class="title">
							附件内容
						</div>

						<div v-if="uploadFiles.length > 0">
							<template v-for="file in uploadFiles">
								<Progress :key="file.uuid" :file="file" :percentage="file.percentage"
									@retry="uploadRetry(file)" @remove="uploadRemove(file)"></Progress>
							</template>
						</div>
						<div v-else class="empty">
							<img :src="assets.emptyImg" class="empty-img" />
							<div class="empty-description">
								暂无附件内容~
							</div>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
	import Upload from '@/components/Upload'
	import RichTextEditor from '@/components/RichText/RichTextEditor'
	import kong from '@/assets/images/kong.png'
	import Progress from '@/components/Progress'
	import {
		debounce
	} from '@/utils/debounce'

	export default {
		components: {
			Upload,
			Progress,
			RichTextEditor
		},
		data() {
			return {
				// 主题ID
				id: '',
				cached: {
					pageNo: 1,
					searchKey: '',
					deviceId: '',
					// areaId: '',
					pubByLike: '',
					deviceNamesLike: '',
					pubTimeStart: '',
					pubTimeEnd: '',
					status: '',
					prop: '',
					order: '',
					searchKey2: '',
					details: ''
				},
				form: {
					title: '', // 主题
					source: '', // 资源
					detail: '', // 内容
					// rclFlag: 0, // 是否升级RCL  0:否  1:是
					sendType: 1, // 1:以通讯组为单位  2:以签约类型为单位 3:以用户未单位 4:以经销商为单位
					sendTargetList: [],
					content: '', // 主题内容
					deviceIdList: [], // 产品ID 数组
					fileIdList: [], // 文件ID 数组
					firmwareList: [] // 固件 数组
				},
				// 状态
				status: 0,
				// 签约类型发送对象
				sendTargetSign: [],
				// 分公司发送对象
				sendTargetDepartment: [],
				// 经销商发送对象
				sendTargetAgent: [],
				// 用户发送对象 sendType = 3
				sendTargetUser: [],
				downloadItem: {
					firmwareName: '',
					firmwareLink: ''
				},
				// 资源数据
				sourceOptions: [],
				// 内容数据
				contentOptions: [],
				// 通讯组数据
				departmentOptions: [],
				// 签约类型数据
				contractOptions: [],
				// 经销商数据
				agentOptions: [],
				// 用户数据
				userOptions: [],
				userOptionsCopy: [],
				// 搜索用用户名称
				searchUserName: '',
				// 机种机型数据
				machineOptions: [],
				// 机型最上级与最下级映射关系
				// 因为最上级只能多选一，需要用该数据来控制
				machineRelations: [],
				// 机型选项
				machineProps: {
					multiple: true,
					emitPath: false,
					value: 'id',
					label: 'name',
					children: 'children',
					disabled: 'disabled'
				},
				// 组织架构级联选择器选项
				departmentProps: {
					multiple: true,
					emitPath: false,
					// checkStrictly: true,
					value: 'id',
					label: 'label',
					children: 'children'
				},
				// 对应el-upload组件的数据
				// 当重新上传时，会清空组件的文件列表数据
				// 正常的上传状态文件列表
				uploadNormalFiles: [],
				// 失败的上传文件列表
				uploadFailedFiles: [],
				// 静态文件
				assets: {
					emptyImg: kong
				},
				rules: {
					title: [{
						required: true,
						message: '请输入主题',
						trigger: 'blur'
					}],
					source: [{
						required: true,
						message: '请选择资源',
						trigger: 'change'
					}],
					detail: [{
						required: true,
						message: '请选择内容',
						trigger: 'change'
					}],
					sendType: [{
						required: true,
						message: '请选择接收对象',
						trigger: 'change'
					}],
					content: [{
						required: true,
						message: '请输入主题内容',
						trigger: 'change'
					}]
				},
				firmwareRules: {
					firmwareLink: [{
						type: 'url',
						message: '请输入正确的地址',
						trigger: 'blur'
					}]
				},
				scrollTop: 0,
				depVisible: false,
				proVisible: false
			}
		},
		watch: {
			// form 表单同步上传成功的文件
			uploadNormalFiles(files) {
				this.form.fileIdList = []
				for (const file of files) {
					if (file.response && file.response.code === '0') {
						this.form.fileIdList.push(file.response.data.id)
					}
				}
			},
			// 控制第一层单选
			machines() {
				// this.setDisabledMachines()
			},
			// 获取所有机型列表数据
			machineOptions() {
				this.setDisabledMachines()
			},
			sendTargetList(val) {
				this.form.sendTargetList = val
			},
			// 签约类型数据变更，产品数据需要同步变更
			sendTargetSign(val) {
				this.form.deviceIdList = []
				this.getMachineOptions()
			},
			sendType(val) {
				// 1:以通讯组为单位  2:以签约类型为单位  4:以经销商类型为单位
				if (val === 1) {
					this.getMachineOptions(true)
				} else if (val === 2) {
					this.form.deviceIdList = []
					this.getMachineOptions()
				} else if (val === 4) {
					this.getMachineOptions(true)
				}
			}
		},
		computed: {
			// 是否为超级管理员
			// 是 才能 (删除或编辑各种状态下的通信箱)
			isSuperAdmin() {
				const userInfoStr = window.sessionStorage.getItem('userInfo')
				if (!userInfoStr) return false

				const userInfo = JSON.parse(userInfoStr)
				if (!userInfo) return false
				if (userInfo.superAdminFlag === '1') return true

				return false
			},
			// 是否是管理员
			// 是 才能 (删除或编辑各种状态下的通信箱)
			isAdmin() {
				const userInfoStr = window.sessionStorage.getItem('userInfo')
				if (!userInfoStr) return false

				const userInfo = JSON.parse(userInfoStr)
				if (!userInfo) return false
				if (userInfo.adminFlag === '1') return true

				return false
			},
			// 所有状态的上传文件
			uploadFiles() {
				let files = []

				if (this.uploadNormalFiles) files.push.apply(files, this.uploadNormalFiles)
				if (this.uploadFailedFiles) files.push.apply(files, this.uploadFailedFiles)
				return files
			},
			machines() {
				return this.form.deviceIdList
			},
			title() {
				if (this.id) return '修改主题'
				return '新增主题'
			},
			sendType() {
				return this.form.sendType
			},
			// 所有的部门列表
			allDepartments() {
				const getChildrens = (department) => {
					const children = []
					if (department.children && department.children.length > 0) {
						for (const child of department.children) {
							children.push(child)
							const c = getChildrens(child)
							children.push.apply(children, c)
						}
					}
					return children
				}

				const departments = []
				for (const department of this.departmentOptions) {
					departments.push(department)
					const children = getChildrens(department)
					departments.push.apply(departments, children)
				}

				return departments
			},
			sendTargetList() {
				const targets = []
				if (this.form.sendType === 1) {
					// 通信组
					for (const t of this.sendTargetDepartment) {
						for (const c of this.allDepartments) {
							if (t === c.id) {
								const obj = {
									sendType: 1,
									sendName: c.label,
									sendVal: t
								}
								targets.push(obj)
								break
							}
						}
					}
				} else if (this.form.sendType === 2) {
					// 签约类型
					for (const t of this.sendTargetSign) {
						for (const c of this.contractOptions) {
							if (t === c.id) {
								const obj = {
									sendType: 2,
									sendName: c.name,
									sendVal: t
								}
								targets.push(obj)
								break
							}
						}
					}
				} else if (this.form.sendType === 4) {
					// 经销商
					for (const t of this.sendTargetAgent) {
						for (const c of this.agentOptions) {
							if (t === c.id) {
								const obj = {
									sendType: 4,
									sendName: c.name,
									sendVal: t
								}
								targets.push(obj)
								break
							}
						}
					}
				}

				// 用户
				if (this.sendTargetUser && this.sendTargetUser.length > 0) {
					for (const userId of this.sendTargetUser) {
						for (const u of this.userOptions) {
							if (userId === u.id) {
								const obj = {
									sendType: 3,
									sendName: u.name,
									sendVal: userId
								}
								targets.push(obj)
							}
						}
					}
				}

				return targets
			},
			depClass() {
				if (this.depVisible) return 'hide-cascader-scroll'
				return 'hide-cascader-scroll-reset'
			},
			proClass() {
				if (this.proVisible) return 'hide-cascader-scroll'
				return 'hide-cascader-scroll-reset'
			},
			detail() {
				return this.form.detail
			},
			showFormSender() {
				// if (this.source === '1412319183414824961') return false
				if (this.detail === '1452459980631355394' || this.detail === '1412319806570958850') return false
				return true
			},
			// 产品名称是否为必须的
			requireDevice() {
				// 当内容为 技术报告提交, 技术报告发布, 技术公告发布 时
				// 产品名称为必填项
				const details = ['1452459980631355394', '1412319806570958850', '1452959462695460865']
				if (details.includes(this.detail)) return true
				return false
			}
		},
		created() {
			this.getDictData()
			this.getAllUserList()
			this.getDepartment()
			this.getDealerList()

			this.id = this.$route.query.id

			if (this.id) this.getDetail()
			if (this.id) this.getContent()
			this.getMachineOptions()

			this.cached.pageNo = this.$route.params.pageNo || 1
			this.cached.searchKey = this.$route.params.searchKey || ''
			this.cached.searchKey2 = this.$route.params.searchKey2 || ''
			// this.cached.areaId = this.$route.params.areaId || ''
			this.cached.deviceId = this.$route.params.deviceId || ''
			this.cached.pubByLike = this.$route.params.pubByLike || ''
			this.cached.deviceNamesLike = this.$route.params.deviceNamesLike || ''
			this.cached.pubTimeStart = this.$route.params.pubTimeStart || ''
			this.cached.pubTimeEnd = this.$route.params.pubTimeEnd || ''
			if (this.$route.params.status === 0) {
				this.cached.status = this.$route.params.status
			} else {
				this.cached.status = this.$route.params.status || ''
			}
			this.cached.prop = this.$route.params.prop || ''
			this.cached.order = this.$route.params.order || ''
			this.cached.sources = this.$route.params.sources || ''
			this.cached.details = this.$route.params.details || ''
			this.cached.readFlag = this.$route.params.readFlag || ''
		},
		methods: {
			// 获取主题数据
			getDetail() {
				if (!this.id) return

				let that = this
				that.$request.post(
					"dataSubjectInfo",
					true, {
						id: that.id
					},
					function (r) {
						if (r.code == "0") {
							that.status = r.data.status
							that.form.title = r.data.title
							that.form.source = r.data.source
							that.form.detail = r.data.detail
							// that.form.deviceIdList = r.data.deviceIdList || []
							// that.form.rclFlag = +r.data.rclFlag
							that.form.sendType = +r.data.sendType
							// that.form.content = r.data.content

							for (const firmware of r.data.firmwareList) {
								const f = {
									firmwareName: firmware.firmwareName,
									firmwareLink: firmware.firmwareLink
								}
								that.form.firmwareList.push(f)
							}

							that.uploadNormalFiles = []
							for (const file of r.data.sysAttachFileList) {
								file.name = file.fileName
								file.status = 'success'
								file.percentage = 100
								file.response = {
									code: '0',
									data: {
										id: file.id
									}
								}
								that.uploadNormalFiles.push(file)
							}

							that.initSendTarget(r.data.sendList)

							that.$nextTick(() => {
								that.form.deviceIdList = r.data.deviceIdList || []
							})
						} else {
							that.$message.error(r.msg)
						}
					}
				)
			},
			// 获取富文本内容
			getContent() {
				if (!this.id) return

				let that = this
				that.$request.post(
					"dataSubjectRichText",
					false, {
						id: that.id
					},
					function (r) {
						if (r.code == "0") {
							that.form.content = r.data.content
						} else {
							that.$message.error(r.msg)
						}
					}
				)
			},
			initSendTarget(sendList) {
				if (!sendList || sendList.length === 0) return

				const depts = []
				const signs = []
				const agents = []
				const users = []
				for (const target of sendList) {
					if (target.sendType === 1) depts.push(target.sendVal)
					if (target.sendType === 2) signs.push(target.sendVal)
					if (target.sendType === 3) users.push(target.sendVal)
					if (target.sendType === 4) agents.push(target.sendVal)
				}

				this.form.sendType = 1
				if (signs.length > 0) {
					this.form.sendType = 2
				}
				if (depts.length > 0) {
					this.form.sendType = 1
				}
				if (agents.length > 0) {
					this.form.sendType = 4
				}

				this.sendTargetDepartment = depts
				this.sendTargetSign = signs
				this.sendTargetAgent = agents
				this.sendTargetUser = users
				this.getDealerList(this.sendTargetAgent.join(','),'');
				this.getAllUserList(this.sendTargetUser.join(','),'');
			},
			// 经销商下拉优化
			remoteDealerMethod(query) {
				if (query == '') {
					return
				}
				this.getDealerList(this.sendTargetAgent.join(','),query);
			},
			// 获取经销商数据
			getDealerList(dealerId,searchKey) {
				let that = this;
				that.$request.post(
					"getDealerLists",
					false,
					{
						searchKey: searchKey,
						dealerId: dealerId
					},
					function (r) {
						if (r.code == "0") {
							that.agentOptions = r.data;
						} else {
							that.$message.error(r.msg);
						}
					}
				);
			},
			// 用户下拉优化
			remoteUserMethod(query) {
				if (query == '') {
					return
				}
				this.getAllUserList(this.sendTargetUser.join(','),query);
			},
			// 获取所有用户数据
			getAllUserList(dealerId,searchKey) {
				let that = this
				that.$request.get(
					"sysUserAll",
					false, {
						searchKey: searchKey,
						dealerId: dealerId
					},
					function (r) {
						if (r.code == "0") {
							that.userOptions = r.data;
						} else {
							that.$message.error(r.msg)
						}
					}
				)
			},
			// 获取资源,内容,签约类型数据
			getDictData() {
				let that = this
				that.$request.post(
					"getListByCodesPerm",
					true, {
						codes: 'sourceType,contentType,contractType'
					},
					function (r) {
						if (r.code == "0") {
							that.sourceOptions = r.data.sourceType
							that.contentOptions = r.data.contentType
							that.contractOptions = r.data.contractType
						} else {
							that.$message.error(r.msg)
						}
					}
				)
			},
			// 获取所有机型数据
			getMachineOptions(all) {
				const getChildrens = (category) => {
					const children = []
					if (category.children && category.children.length > 0) {
						for (const child of category.children) {
							const c = getChildrens(child)
							children.push.apply(children, c)
						}
					} else {
						children.push(category.id)
					}

					return children
				}

				let contractTypeIds = ''
				if (all) {
					// 获取所有产品数据
				} else {
					// 根据签约类型获取产品数
					if (this.sendTargetSign) {
						contractTypeIds = this.sendTargetSign.join(',')
					}
				}

				let that = this
				that.$request.post(
					"sysModelDevice",
					false, {
						contractTypeIds: contractTypeIds
					},
					function (r) {
						if (r.code == "0") {
							that.machineRelations = []
							const machineOptions = r.data

							for (const category of machineOptions) {
								category.disabled = false
								const lastChildrens = getChildrens(category)
								const relation = [category.id, lastChildrens]
								that.machineRelations.push(relation)
							}

							that.machineOptions = machineOptions
						} else {
							that.$message.error(r.msg);
						}
					}
				)
			},
			// 获取通讯组数据
			getDepartment() {
				let that = this
				that.$request.get(
					"sysDepartSelector",
					false, {},
					function (r) {
						if (r.code == "0") {
							if (r.data && r.data.tree) {
								that.departmentOptions = r.data.tree
							}
						} else {
							that.$message.error(r.msg)
						}
					}
				)
			},
			setDisabledMachines() {
				let first = ''
				for (const machine of this.machines) {
					for (const relation of this.machineRelations) {
						const f = relation[0]
						const childrens = relation[1]

						if (childrens.indexOf(machine) >= 0) {
							first = f
							break
						}
					}

					break
				}

				// for (const category of this.machineOptions) {
				// 	if (first) {
				// 		category.disabled = category.id === first ? false : true
				// 	} else {
				// 		category.disabled = false
				// 	}
				// }
			},
			// 添加固件下载地址
			addDownloadItem() {
				this.downloadItem.firmwareName = this.downloadItem.firmwareName.trim()
				this.downloadItem.firmwareLink = this.downloadItem.firmwareLink.trim()

				this.$refs['firmware-form'].validate((valid) => {
					if (valid) {
						const obj = Object.assign({}, this.downloadItem)
						this.form.firmwareList.push(obj)
						this.downloadItem.firmwareName = ''
						this.downloadItem.firmwareLink = ''
					} else {
						return false
					}
				})
			},
			// 删除固件下载地址
			removeDownloadItem(item) {
				this.form.firmwareList.splice(this.form.firmwareList.indexOf(item), 1)
			},
			// 上传失败重试
			uploadRetry(file) {
				this.uploadFailedFiles.splice(this.uploadFailedFiles.indexOf(file), 1)
				file.status = 'ready'
				this.uploadNormalFiles.push(file)
				this.$refs.upload.submit()
			},
			// 取消上传
			uploadRemove(file) {
				// this.$refs.upload.abort(file)
				// this.uploadNormalFiles.splice(this.uploadNormalFiles.indexOf(file), 1)
				if (this.uploadNormalFiles.indexOf(file) > -1) {
					this.uploadNormalFiles.splice(this.uploadNormalFiles.indexOf(file), 1)
				}
				if (this.uploadFailedFiles.indexOf(file) > -1) {
					this.uploadFailedFiles.splice(this.uploadFailedFiles.indexOf(file), 1)
				}
			},
			// 新增|修改主题
			submit: debounce(function () {
				this.form.title = this.form.title.trim()

				this.$refs['form'].validate((valid) => {
					if (valid) {
						if (this.showFormSender) {
							if (!this.form.sendTargetList || this.form.sendTargetList.length === 0) {
								this.$message.error('请选择接收对象或用户')
								return false
							}
						} else {
							this.form.sendTargetList = []
						}
						
						// 判断产品名称是否为必填项
						if (this.requireDevice && this.form.deviceIdList.length === 0) {
							this.$message.error('请选择产品名称')
							return false
						}

						if (this.id) {
							// 修改
							const data = Object.assign(this.form, {
								id: this.id
							})

							let that = this
							that.$request.post(
								"dataSubjectUpdate",
								true,
								data,
								function (r) {
									if (r.code == "0") {
										that.$message.success('保存成功')
										that.$router.go(-1)
									} else {
										that.$message.error(r.msg)
									}
								}
							)
						} else {
							// 新增
							let that = this

							that.$request.post(
								"dataSubjectAdd",
								true,
								that.form,
								function (r) {
									if (r.code == "0") {
										that.$message.success('保存成功')
										that.$router.go(-1)
									} else {
										that.$message.error(r.msg)
									}
								}
							)
						}
					} else {
						return false
					}
				})
			}, 1000),
			// 提交
			release: debounce(function () {
				this.$refs['form'].validate((valid) => {
					if (valid) {
						if (this.showFormSender) {
							if (!this.form.sendTargetList || this.form.sendTargetList.length === 0) {
								this.$message.error('请选择接收对象或用户')
								return false
							}
						} else {
							this.form.sendTargetList = []
						}

						// 判断产品名称是否为必填项
						if (this.requireDevice && this.form.deviceIdList.length === 0) {
							this.$message.error('请选择产品名称')
							return false
						}

						this.$confirm('是否提交当前主题?', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
						}).then(() => {
							let data = Object.assign({}, this.form)
							if (this.id) {
								const d = Object.assign(data, {
									id: this.id
								})
								data = d
							}

							let that = this
							that.$request.post(
								"dataSubjectPub",
								true,
								data,
								function (r) {
									if (r.code == "0") {
										that.$message.success('提交成功')
										that.$router.go(-1)
									} else {
										that.$message.error(r.msg)
									}
								}
							)
						}).catch(() => {})
					} else {
						return false
					}
				})
			}, 1000),
			goBack() {
				this.$router.push({
					name:  this.$route.params.isTech === 1 ? 'technique' : 'mailbox',
					params: {
						pageNo: this.cached.pageNo,
						searchKey: this.cached.searchKey,
						searchKey2: this.cached.searchKey2,
						// areaId: this.cached.areaId,
						deviceId: this.cached.deviceId,
						pubByLike: this.cached.pubByLike,
						deviceNamesLike: this.cached.deviceNamesLike,
						pubTimeStart: this.cached.pubTimeStart,
						pubTimeEnd: this.cached.pubTimeEnd,
						status: this.cached.status,
						prop: this.cached.prop,
						order: this.cached.order,
						readFlag: this.cached.readFlag,
						sources: this.cached.sources,
						details: this.cached.details
					}
				})
			},
			// 解决cascader组件margin-bottom: -17px 失效的问题
			depCascaderVisableChange(visible) {
				this.depVisible = visible
			},
			proCascaderVisableChange(visible) {
				this.proVisible = visible
			},
			// 处理吸顶时title隐藏
			handleScroll() {
				this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
			}
		},
		mounted() {
			window.addEventListener('scroll',this.handleScroll);
		},
		destroyed() {
			window.removeEventListener('scroll', this.handleScroll);
		}
	}

</script>

<style lang="scss" scoped>
	@media screen and (max-width: 767px) {
		.content-row {
			flex-direction: column;
		}
	}

	.rcl-checkbox {
		background-color: rgba(244, 249, 255, 1);

		/deep/ .el-checkbox__input {
			padding-left: 10px;

			.el-checkbox__inner {
				border-color: rgba(17, 127, 252, 1);

				&:hover {
					border-color: rgba(17, 127, 252, 1);
				}
			}
		}
	}

	.send-type-checkbox {
		/deep/ .el-checkbox__input {
			.el-checkbox__inner {
				border-color: rgba(17, 127, 252, 1);

				&:hover {
					border-color: rgba(17, 127, 252, 1);
				}
			}
		}
	}

	.font14 {
		/deep/ .el-cascader__search-input {
			font-size: 14px;
		}
	}

</style>

<style lang="scss">
	.user-select {
		max-width: 300px;
	}

</style>
